import HTTP from "@/helpers/http-common"
import helpers from "@/helpers/common"
import i18n from "@/plugins/i18n"

export default {
  async fetchPage({ dispatch, commit }, { payload }) {
    return HTTP.get(
      "api/v1/document/" + payload.key + "/page/" + payload.pageno
    )
      .then((response) => {
        // console.log("Page ==>", response)
        if (response.data && response.data.data) {
          var page = response.data.data
          var layout
          var response_layout = response.data.data.layout
          var contrastZones = null
          var response_contrast_layout = null
          //layout could be an empty array
          if (typeof response_layout != "string") {
            layout = response_layout
          } else {
            layout = JSON.parse(response.data.data.layout)
            //need to sort the layout to make sure it's displayed correctly
            layout.sort(function (a, b) {
              if (parseInt(a.order) < parseInt(b.order)) return -1
              if (parseInt(a.order) > parseInt(b.order)) return 1
              return 0
            })
          }

          /*
						Need section for the contrast layout. Would be nice if we can read the env vars from here to 
						keep this from running.  Disabled for now.
					*/

          /*
					if (page.contrast_layout !== undefined && page.contrast_layout !== null && page.contrast_layout.length > 0) {
						var contrasts = JSON.parse(page.contrast_layout)
						contrastZones = []
						for (i = 0; i < contrasts.length; ++i) {
							contrasts[i].id = helpers.generateUUID()
							contrasts[i].name = 'Contrast issue ' + (i + 1).toString()
							contrastZones.push(contrasts[i])
						}

						console.log('contrast zones is: ', contrastZones)
						commit("SET_CONTRAST_ZONES", contrastZones)
					}
					*/

          // If the page is an ocr page, the ocr data was processed async
          // and needs to be matched and put back in the proper ocr zones
          if (page.ocr_data !== undefined && page.ocr_data !== null) {
            var uniq = []
            for (var i = 0; i < layout.length; ++i) {
              if (layout[i]["source"] == "OCR") {
                for (var o = 0; o < page.ocr_data.length; ++o) {
                  if (
                    page.ocr_data[o] !== undefined &&
                    page.ocr_data[o]["zone_name"] == layout[i]["name"]
                  ) {
                    layout[i]["ocrText"] = page.ocr_data[o]["zone_ocr_data"]
                    break
                  }
                }

                if (Object.prototype.hasOwnProperty.call(uniq, layout[i].id)) {
                  console.log(
                    "Found a duplicate id, creating a new one for zone ",
                    layout[i].name
                  )
                  layout[i].id = helpers.generateUUID()
                }
                uniq.push(layout[i].id)
                //   $scope.zones.push(layout[i]);
              } else {
                if (Object.prototype.hasOwnProperty.call(uniq, layout[i].id)) {
                  console.log(
                    "Found a duplicate id, creating a new one for zone ",
                    layout[i].name
                  )
                  layout[i].id = helpers.generateUUID()
                }
                uniq.push(layout[i].id)
                //   $scope.zones.push(layout[i]);
              }
            }
          } else {
            page.ocr_data = undefined
          }

          //section for last savedBy.  add page repeats
          if (!page.last_saved_by_key) {
            if (page.repeats !== undefined) {
              for (i = 0; i < page.repeats.length; ++i) {
                layout.push(JSON.parse(JSON.stringify(page.repeats[i])))
              }
            }
          } else {
            if (page.repeats !== undefined) {
              for (i = 0; i < page.repeats.length; ++i) {
                layout.push(JSON.parse(JSON.stringify(page.repeats[i])))
              }
            }
          }

          commit("SET_PAGE", response.data.data)
          commit("SET_LAYOUT", layout)
          commit("SET_SELECTED_ZONE", undefined)
          commit("INIT_UNDO", layout)
        }
      })
      .catch((error) => {
        console.log(error)

        if (error.response !== undefined) {
          // return Promise.reject(error)
          return error.response.status
        } else {
          return 500
          // return Promise.reject(500)
        }
      })
  },

  /*
		IMPORTANT!
		getPage just returns the page without saving anything in the store.
		used for page merges etc when we're not currently on the page we're targeting,
		but we don't want to set the retrieved page in store
		return a promise and resolve with page
	*/
  async getPage({ dispatch, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      HTTP.get("api/v1/document/" + payload.key + "/page/" + payload.pageno)
        .then((response) => {
          if (response.data) {
            resolve({ page: response.data.data })
          }
        })
        .catch((error) => {
          console.log("error trying to get page:" + error)
          reject(error)
        })
    })
  },

  removeZone({ dispatch, commit }, zone) {
    console.log("Removing => ", zone)
  },
  //delete the ignore zones.  called from page component
  async deleteIgnoreZones({ state, dispatch, commit }, { payload }) {
    // console.log("in deleteIgnoreZones")
    return new Promise((resolve, reject) => {
      HTTP.put("/api/v1/document/" + payload.key + "/ignore", {
        zone: JSON.stringify(payload.zone),
        csrf_token: state.csrf_token,
      })
        .then((response) => {
          if (response) {
            resolve("success")
            commit("REMOVE_IGNORE_ZONE", payload.zone)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  //this is basically commitLayout from the classic angular code
  async savePage({ dispatch, commit }, { payload }) {
    return HTTP.put(
      "api/v1/document/" + payload.key + "/page/" + payload.pageno,
      {
        csrf_token: payload.csrf_token,
        page: payload.page,
        layout: JSON.stringify(payload.layout),
        contrast_layout: JSON.stringify(payload.contrastZones),
      }
    )
      .then((response) => {
        // console.log("in savePage. response.data is: ", response.data)
        commit("SET_SAVED_LAYOUT", payload.layout)

        if (response.data) {
          //rjr need to save the repeats that are returned from the save
          if (response.data.repeats !== undefined) {
            commit("UPDATE_PAGE_REPEATS", response.data.repeats)
          }
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.response !== undefined) {
          return error.response.status
        } else {
          return 500
        }
      })
  },
  async markDone({ state, dispatch, commit }, doc_key) {
    if (state.page.validated) {
      commit("MARK_PAGE", false)
    } else {
      commit("MARK_PAGE", true)
    }

    const payload = {
      key: doc_key,
      pageno: state.page.page_no,
      csrf_token: state.csrf_token,
      page: state.page,
      layout: state.layout,
    }
    return dispatch("savePage", { payload })
  },
  async setSensitivityAllPages({ state }, { payload }) {
    return HTTP.post(
      "/api/v1/document/" +
        payload.doc_key +
        "/sensitivity/" +
        payload.radius +
        "/" +
        payload.page_no,
      {
        csrf_token: state.csrf_token,
        fields_visible: state.page.fields_visible,
        images_visible: state.page.images_visible,
      }
    )
      .then((response) => {
        console.log(response.data)

        if (response.data) {
          // commit("SET_PAGE", response.data.data);
          // commit("setLayout", JSON.parse(response.data.data.layout))
        }
      })
      .catch((error) => {
        console.log(error)

        if (error.response !== undefined) {
          // return Promise.reject(error)
          return error.response.status
        } else {
          return 500
          // return Promise.reject(500)
        }
      })
  },
  async setOrderAllPages({ state }, { payload }) {
    return HTTP.post(
      "/api/v1/document/" + payload.doc_key + "/reorder/" + payload.order,
      {
        csrf_token: state.csrf_token,
      }
    )
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)

        if (error.response !== undefined) {
          // return Promise.reject(error)
          return error.response.status
        } else {
          return 500
          // return Promise.reject(500)
        }
      })
  },
  async previewPage({ state, commit }, doc_key) {
    return HTTP.put("/document/" + doc_key + "/preview/" + state.page.page_no, {
      csrf_token: state.csrf_token,
      page: state.page,
      layout: JSON.stringify(state.layout),
    })
      .then((response) => {
        // console.log(response.data)

        if (response.data) {
          // commit("SET_PAGE", response.data.data);
          //commit("SET_LAYOUT", JSON.parse(response.data.data.layout))
        }
      })
      .catch((error) => {
        console.log(error)

        if (error.response !== undefined) {
          // return Promise.reject(error)
          return error.response.status
        } else {
          return 500
          // return Promise.reject(500)
        }
      })
  },
  async fixedPreviewPage({ state }, doc_key) {
    return HTTP.put(
      "/document/" + doc_key + "/fixedpreview/" + state.page.page_no,
      {
        csrf_token: state.csrf_token,
        page: state.page,
        layout: JSON.stringify(state.layout),
      }
    )
      .then((response) => {
        // console.log(response.data)

        if (response.data) {
          // commit("SET_PAGE", response.data.data);
          // commit("setLayout", JSON.parse(response.data.data.layout))
        }
      })
      .catch((error) => {
        console.log(error)

        if (error.response !== undefined) {
          // return Promise.reject(error)
          return error.response.status
        } else {
          return 500
          // return Promise.reject(500)
        }
      })
  },
  /*
		Re-written below with promises
	
	async customZone({ state, commit }, { payload }) {
		console.log('Calling custom')

		commit("SET_CUSTOM_PROGRESS", { zoneId: payload.zone.id })

		return HTTP.post('/api/v1/document/' + payload.doc_key + '/page/' + state.page.page_no + '/custom',
			{
				zone: JSON.stringify(payload.zone),
				csrf_token: state.csrf_token
			}).then((response) => {
				commit("SET_CUSTOM_RESULT", { zoneId: payload.zone.id, customText: response.data.data.customText })
				return response.data
			})
			.catch((error) => {
				console.log(error)

				if (error.response !== undefined) {
					// return Promise.reject(error)
					return error.response.status
				} else {
					return 500
					// return Promise.reject(500)
				}
			})
	},
	*/

  /*
	Handles call for custom zone source
	*/
  async customZone({ state, commit }, { payload }) {
    // console.log("in customZone")
    return new Promise((resolve, reject) => {
      commit("SET_CUSTOM_PROGRESS", { zoneId: payload.zone.id })

      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/custom",
        {
          zone: JSON.stringify(payload.zone),
          csrf_token: state.csrf_token,
        }
      )
        .then((response) => {
          // console.log("response from customZone is: ", response)
          commit("SET_CUSTOM_RESULT", {
            zone: payload.zone,
            customText: response.data.data.customText,
          })
          if (response.data !== undefined) {
            resolve(response.data)
          }
        })
        .catch((error) => {
          console.log("error in custom zone: ", error)
          reject(error)
        })
    })
  },
  async getSelectedCellCustomText({ dispatch, commit }, { payload }) {
    if (payload.cell) {
      if (
        (payload.cell.source == "Custom" && (payload.cell.customText === undefined || payload.cell.customText === "")) ||
        (payload.cell.source == "Actual" && (payload.cell.actual_text === undefined || payload.cell.actual_text === ""))
      ) {
        var correctedCell = {
          x: payload.cell["x"] + payload.tableZoneX,
          y: payload.cell["y"] + payload.tableZoneY,
          w: payload.cell["width"],
          h: payload.cell["height"],
        }

        dispatch(
          "page/customSource",
          { payload: { ...payload, zone: JSON.stringify(correctedCell) } },
          { root: true }
        )
          .then((response) => {
            var data = undefined
            if (
              response.data !== undefined &&
              response.data.data !== undefined
            ) {
              data = response.data.data
            } else {
              data = response.data
            }
            if (data !== undefined && data.customText != undefined) {
              // console.log("Custom text: ", data.customText)
              if (payload.cell.source == "Custom") {
                commit("UPDATE_SELECTED_CELL_CUSTOM_TEXT", data.customText)
              } else {
                commit("UPDATE_SELECTED_CELL_ACTUAL_TEXT", data.customText)
              }
            }
          })
          .catch((error) => {
            console.log("Error getting custom text:", error)
          })
      }
    }
  },
  /*
		Re-written below with promises

	async ocrZone({ state, commit }, { payload }) {

		const params = new URLSearchParams()
		params.append('csrf_token', state.csrf_token)
		params.append('name', payload.zone.name)
		params.append('id', payload.zone.id)
		params.append('left', payload.zone.x)
		params.append('top', payload.zone.y)
		params.append('width', payload.zone.w)
		params.append('height', payload.zone.h)

		return HTTP.post("/api/v1/document/" + payload.doc_key + '/page/' + state.page.page_no + '/ocrzone', params)
			.then((response) => {
				if (response.data !== undefined && response.data.data !== undefined && response.data.data.transactId != undefined) {
					commit("SET_OCR_ID", { zoneId: payload.zone.id, transactId: response.data.data.transactId })
				}
				return response.data

			})
			.catch((error) => {
				console.log(error)

				if (error.response !== undefined) {
					// return Promise.reject(error)
					return error.response.status
				} else {
					return 500
					// return Promise.reject(500)
				}
			})
	},
	*/

  async ocrZone({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      // console.log("in ocr zone. payload: ", payload)
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/ocrzone",
        {
          csrf_token: state.csrf_token,
          name: payload.name,
          id: payload.id,
          left: payload.left,
          top: payload.top,
          width: payload.width,
          height: payload.height,
        }
      )
        .then((response) => {
          if (
            response.data !== undefined &&
            response.data.data !== undefined &&
            response.data.data.transactId != undefined
          ) {
            commit("SET_OCR_ID", { zoneId: payload.id, transactId: response.data.data.transactId })
            resolve(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async ocrListZone({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      // console.log("in ocrListZone call")
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/ocrlist",
        {
          lists: JSON.stringify(payload.lists),
          zoneId: payload.zone.id,
          csrf_token: state.csrf_token,
        }
      )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {})
    })
  },

  /*
		Use this as a TEMPLATE for new actions
	*/
  /*
	async ocrList({state, commit}, {payload}) {

		return new Promise((resolve, reject) => {

			HTTP.post("/api/v1/document/" + payload.doc_key + "/page/" + payload.page_no + "/ocrlist",
			{

			}).then( (response) => {

			}).catch((error) => {
				
			})

		})
	},
	*/

  async fetchMlTables({ state, commit }, { payload }) {
    // console.log("in fetchMlTables action.  payload: ", payload)

    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/mltable",
        {
          name: payload.name,
          left: payload.left,
          top: payload.top,
          width: payload.width,
          height: payload.height,
          tagged: payload.tagged,
          zones: payload.zones,
        }
      )
        .then((response) => {
          //console.log('got the ml tables:', response)
          /*
						TODO: does it make sense to set ml tables in scope, then call getter
						from original caller? prob not.  there could be more than one table on 
						a page.  Might be better to return the ml tables
					*/
          //commit("SET_ML_TABLES", response.data.tables)
          resolve({ data: response.data })
        })
        .catch((error) => {
          console.log("error while getting the ml table", error)
          reject(error)
        })
    })
  },

  async fetchTablePreview({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/table",
        {
          name: payload.name,
          left: payload.left,
          top: payload.top,
          width: payload.width,
          height: payload.height,
          radius: payload.radius,
          tagged: payload.tagged,
          zones: payload.zones,
          auto_detect: payload.auto_detect,
        }
      )
        .then((response) => {
          //console.log('got the table preview:', response)
          /*
						TODO: does it make sense to set ml tables in scope, then call getter
						from original caller? prob not.  there could be more than one table on 
						a page.  Might be better to return the ml tables
					*/
          resolve({ data: response.data })
        })
        .catch((error) => {
          console.log("error while getting the ml table", error)
          reject(error)
        })
    })
  },
  async previewTableLayout({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/previewtable/" +
          payload.page_no +
          "/" +
          payload.tableZone.id,
        {
          layout: payload.layout,
          childZones: payload.childZones,
        }
      )
        .then((response) => {
          // console.log("in action response: ", response)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /*
		for ocr all cells
	*/
  async ocrTable({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/ocrtable",
        {
          table: payload.table,
        }
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /*
		for custom all cells
	*/
  async customTable({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/customtable",
        {
          zone: payload.zone,
        }
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /*
		for source changed
	*/
  async customSource({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/custom",
        {
          zone: payload.zone,
        }
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /*
		for getOcr ie ocrTableZone
	*/
  async getSelectedCellOCRText({ state, commit }, { payload }) {
    if (payload.cell) {
      var correctedCell = {
        x: payload.cell["x"] + payload.tableZoneX,
        y: payload.cell["y"] + payload.tableZoneY,
        w: payload.cell["width"],
        h: payload.cell["height"],
      }

      return new Promise((resolve, reject) => {
        HTTP.post(
          "/api/v1/document/" +
            payload.doc_key +
            "/page/" +
            payload.page_no +
            "/ocrzone",
          {
            name: payload.cell.name,
            id: payload.cell.id,
            left: correctedCell.x,
            top: correctedCell.y,
            width: correctedCell.w,
            height: correctedCell.h,
          }
        )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },

  async loadMlTOCDetectedZones({ state, commit }, { payload }) {
    // console.log("in load ml toc action")
    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/detecttocml",
        {
          name: payload.name,
          id: payload.id,
          order: payload.order,
          left: payload.left,
          top: payload.top,
          width: payload.width,
          height: payload.height,
        }
      )
        .then((response) => {
          // console.log("in load ml toc action. response is: ", response)
          var data = undefined
          if (response.data !== undefined && response.data.data !== undefined) {
            data = response.data.data
          } else {
            data = response.data
          }
          //need to return the data
          // console.log("in ml toc action. resolving data as: ", data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async loadOriginalListDetect({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/detectlist",
        {
          name: payload.name,
          id: payload.id,
          order: payload.order,
          left: payload.left,
          top: payload.top,
          width: payload.width,
          height: payload.height,
        }
      )
        .then((response) => {
          // console.log(response)
          var data = undefined
          if (response.data !== undefined && response.data.data !== undefined) {
            data = response.data.data
          } else {
            data = response.data
          }
          data.zones[0].listSensitivity = 1
          commit("UPDATE_LAYOUT_ZONE", data.zones[0])
          commit("UPDATE_LIST_SPLITTER", data.zones[0])
          //need to return the data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async loadMlListDetectedZones({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        "/api/v1/document/" +
          payload.doc_key +
          "/page/" +
          payload.page_no +
          "/detectlistml",
        {
          name: payload.name,
          id: payload.id,
          order: payload.order,
          left: payload.left,
          top: payload.top,
          width: payload.width,
          height: payload.height,
        }
      )
        .then((response) => {
          // console.log(response)
          var data = undefined
          if (response.data !== undefined && response.data.data !== undefined) {
            data = response.data.data
          } else {
            data = response.data
          }
          //need to return the data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async addUndo({ commit }, { payload }) {
    if (payload !== undefined) {
      commit("ADD_UNDO", payload.layout)
    }
  },

  async Undo({ commit }) {
    commit("UNDO")
  },

  async Redo({ commit }) {
    commit("REDO")
  },

  async ocrAllZones({ state, commit, dispatch }, { payload }) {
		// console.log("in ocrAllZones")
		if (!state.ocrAllZonesStatus.inProgress) {

			let totalZones = 0

			commit("UPDATE_PAGE_OCR_PROGRESS_MSG", { pageProgress: 0, pageProgressMessage: i18n.t("page.sidebarPage.msg.ocrAllProgress.preparing") })

			let ocrTasks = [];
			for (var i = 0; i < state.layout.length; i++) {
				let currentZone = state.layout[i]
				if (currentZone != undefined && currentZone.source == 'OCR') {
					if (currentZone.ocrText === undefined || currentZone.ocrText.length == '') {
						totalZones++
						//   dispatch( getOcr($scope.zones[i]))
						ocrTasks.push(new Promise((resolve, reject) => {
							dispatch(
								"page/ocrZone",
								{
									payload: {
										doc_key: payload.doc_key,
										page_no: payload.page_no,
										name: currentZone.name,
										id: currentZone.id,
										left: currentZone.x,
										top: currentZone.y,
										width: currentZone.w,
										height: currentZone.h
									}
								},
								{ root: true }
							).then((response) => {
								// console.log("response from ocrZone: ", response)
								commit("UPDATE_ZONE_OCR_STATUS", { id: currentZone.id, transactId: response.data.transactId })
							})
							.catch((error) => {
								console.log("Error while getting ocr for zone ", currentZone.name)
							})
						}))
					}
				}
			}

			if (totalZones == 0) {
				let pageProgress = 100
				commit("UPDATE_PAGE_OCR_PROGRESS_MSG", { pageProgress: 100, pageProgressMessage: i18n.t("page.sidebarPage.msg.ocrAllProgress.alreadyProcessed") })
			}
			else {
				commit("UPDATE_PAGE_OCR_PROGRESS_MSG", { pageProgress: 0, pageProgressMessage: i18n.t("page.sidebarPage.msg.ocrAllProgress.processing") })
				commit("INIT_PAGE_OCR_PROGRESS", totalZones)
				

			}

		}
	},
}
